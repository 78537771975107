import layout from '../styles/layout.module.css'
import guides from '../styles/guides.module.css'
import Head from 'next/head'
import Container from 'react-bootstrap/Container'
import { useTranslation } from 'next-i18next'
import { GetStaticProps } from 'src/var/next/next'
import { withNamespace } from '../src/util/src/i18next'
import { getTranslations } from 'src/var/next/next-i18next'
import Link from 'next/link'

export default function Guides(props: OverviewProps) {
  // const { } = props

  const { t } = useTranslation(translationNamespaces)
  const tc = withNamespace(t, 'common')
  const tPages = withNamespace(t, 'pages')
  const title = t('title')
  const description = t('description')

  return (
    <Container className={layout.main}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
      <h1 className={guides.instructionHeader}>{title}</h1>
      <hr />
      <h2 className={guides.instructionSubheader}>{t('defi')}</h2>
      <ul>
        <li><Link href="/defi/make-money-on-BUSD-via-AlpacaFinance">{tPages('defi.make-money-on-BUSD-via-AlpacaFinance.title')}</Link></li>
      </ul>
      <h2 className={guides.instructionSubheader}>{t('p2e')}</h2>
      <ul>
        <li><Link href="/play-to-earn/games">{tPages('play-to-earn.overview.title')}</Link></li>
      </ul>
    </Container>
  )
}

// const ParamsSchema = z.object({
//   username: UsernameSchema,
// })

export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context
  const translations = await getTranslations(context, translationNamespaces)
  return {
    props: {
      ...translations,
    },
  }
}

const translationNamespaces = ['guides', 'pages', 'common']

interface OverviewProps {

}
